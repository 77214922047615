import React, { ReactNode, forwardRef } from "react";
import { Collapse } from "../Collapse";
import { NavbarRenderProps } from "./Navbar";
import { NavbarBrand } from "./NavbarBrand";
import { NavbarToggler } from "./NavbarToggler";

interface SimpleNavBarProps extends NavbarRenderProps {
  brand?: ReactNode;
  children?: ReactNode;
}

export const SimpleNavBar = forwardRef<HTMLDivElement, SimpleNavBarProps>(
  ({ children, set, brand, ...p }, ref) => (
    <>
      {typeof brand === "string" ? (
        <NavbarBrand href="#">{brand}</NavbarBrand>
      ) : (
        brand
      )}
      <NavbarToggler onClick={() => set(!p.visible)} />
      <Collapse {...p} ref={ref}>
        {children}
      </Collapse>
    </>
  )
);
