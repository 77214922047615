import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import classnames from "classnames";

export interface ProgressBarProps extends BSDiv {
  value?: number;
  variant?: "solid" | "stripe";
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    { className = "", variant = "solid", value = 0, children, ...props },
    ref
  ) => {
    className = classnames(`progress-bar ${className}`, {
      "progress-bar-striped progress-bar-animated": variant === "stripe"
    })

    return (
      <div
        {...props}
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width: `${value}%` }}
        role="progressbar"
        className={className}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);