import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const NavbarNav = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, className = "", ...props }, ref) => {
    return (
      <div
        {...props}
        className={`navbar-nav ${className}`}
        role="navigation"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);