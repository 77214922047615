import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import { useSizeToAbbr } from "../../hooks";
import classnames from "classnames";

export interface PaginationProps extends BSDiv {
  size?: "small" | "large" | "sm" | "lg";
}

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  ({ children, className = "", size, ...props }, ref) => {
    const abbrSize = useSizeToAbbr(size);
    
    className = classnames(`pagination ${className}`, {
      [`pagination-${abbrSize}`]: abbrSize
    })

    return (
      <nav {...props} ref={ref}>
        <ul className={className}>{children}</ul>
      </nav>
    );
  }
);