import React, { forwardRef } from "react";
import { BSParagraph } from "../../types";

export const CardText = forwardRef<HTMLParagraphElement, BSParagraph>(
  ({ children, className = "", component: Component = "p", ...props }, ref) => {
    return (
      <Component {...props} className={`card-text ${className}`} ref={ref}>
        {children}
      </Component>
    );
  }
);