import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { BSDiv } from "../../types";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { ModalBackdrop } from "./ModalBackdrop";
import { ModalDialog } from "./ModalDialog";
import { ModalContent } from "./ModalContent";
import { useForkedRef } from "../../hooks";

export interface ModalProps extends BSDiv {
  animationVariants?: Variants;
  alignment?: "top" | "center";
  transition?: "spring" | "tween";
  visible?: boolean;
  scrollable?: boolean;
  onDismiss?: () => void;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      className = "",
      visible,
      children,
      transition = "tween",
      onDismiss,
      scrollable = false,
      alignment = "top",
      animationVariants,
    },
    forwardedRef
  ) => {
    const [prevFocusedEl, setPrevFocusedEl] = useState<HTMLElement>();
    const modalRef = useRef<HTMLDivElement>(null);
    const ref = useForkedRef(forwardedRef, modalRef);

    // set focus to element on open, restore focus to prev focused on close
    useEffect(() => {
      if (visible) {
        document.body.classList.add("modal-open");
        setPrevFocusedEl((document.activeElement as HTMLElement) || undefined);
        modalRef.current && modalRef.current.focus();
      } else {
        document.body.classList.remove("modal-open");
        prevFocusedEl && prevFocusedEl.focus();
      }
      return () => document.body.classList.remove("modal-open");
    }, [visible]);

    // keyboard trap tab, restrict to element
    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === "Escape") {
          return onDismiss && onDismiss();
        }

        const focusableElements = modalRef.current
          ? Array.prototype.slice.call(
              modalRef.current.querySelectorAll(
                "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex]"
              )
            )
          : [];

        if (e.key === "Tab") {
          if (focusableElements.length === 1) {
            return e.preventDefault();
          }

          if (e.shiftKey) {
            if (document.activeElement === focusableElements[0]) {
              e.preventDefault();
              focusableElements[focusableElements.length - 1].focus();
            }
          } else {
            if (
              document.activeElement ===
              focusableElements[focusableElements.length - 1]
            ) {
              e.preventDefault();
              focusableElements[0].focus();
            }
          }
        }
      },
      [modalRef, onDismiss]
    );

    return (
      <AnimatePresence>
        {visible && (
          <ModalBackdrop
            key="backdrop"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 0.5 },
            }}
            className="show"
          />
        )}
        {visible && (
          <motion.div
            ref={ref}
            onClick={onDismiss}
            onKeyDown={handleKeyDown}
            aria-modal={true}
            key="modal"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={
              animationVariants || {
                hidden: {
                  opacity: 0,
                  y: transition === "tween" ? -50 : -150,
                  transition: { ease: "easeOut", duration: 0.3 },
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition:
                    transition === "tween"
                      ? { ease: "easeOut", duration: 0.3 }
                      : {},
                },
              }
            }
            className={`modal show ${className}`}
            tabIndex={-1}
            role="dialog"
            style={{ display: "block" }}
          >
            <ModalDialog
              onClick={(e) => e.stopPropagation()}
              className="show"
              scrollable={scrollable}
              alignment={alignment}
            >
              <ModalContent>{children}</ModalContent>
            </ModalDialog>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);
