import React, { forwardRef } from "react";
import { BSList  } from "../../types";
import classnames from "classnames";

export interface NavProps extends BSList {
  variant?: "tabs" | "pills";
  layout?: "fill" | "justified";
}

export const Nav = forwardRef<HTMLUListElement, NavProps>(
  ({ children, className = "", variant, layout, ...props }, ref) => {
    className = classnames(`nav ${className}`, {
      [`nav-${variant}`]: variant,
      [`nav-${layout}`]: layout
    })
    return (
      <ul
        {...props}
        className={className}
        role="navigation"
        ref={ref}
      >
        {children}
      </ul>
    );
  }
);