import React, { forwardRef } from "react";
import { BSAnchor  } from "../../types";
import classnames from "classnames";

export interface NavLinkProps extends BSAnchor {
  disabled?: boolean;
  active?: boolean;
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, disabled, className = "", active, component: Component = "a", ...props }, ref) => {
    className = classnames(`nav-link ${className}`, {
      "active": active,
      "disabled": disabled
    })

    if (disabled) {
      props.tabIndex = -1;
      props["aria-disabled"] = true;
    }

    return (
      <Component {...props} className={className} ref={ref}>
        {children}
      </Component>
    );
  }
);