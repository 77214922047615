import React, { forwardRef } from "react";
import { BSSpan } from "../../types";

export const NavbarText = forwardRef<HTMLSpanElement, BSSpan>(
  ({ children, className = "", component: Component = "span", ...props }, ref) => {
    return (
      <Component
        {...props}
        className={`navbar-text ${className}`}
        role="navigation"
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);