import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import { Close } from "../Button";

export interface ModalHeaderProps extends BSDiv {
  onDismiss?: () => void;
}

export const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
  ({ className = "", children, onDismiss, ...props }, ref) => (
    <div {...props} className={`modal-header ${className}`} ref={ref}>
      {children}
      {onDismiss && <Close onClick={onDismiss} />}
    </div>
  )
);