import React, { forwardRef } from "react";
import { BSButton } from "../../types";

export const NavbarToggler = forwardRef<HTMLButtonElement, BSButton>(
  ({ children, className = "", component: Component = "button", ...props }, ref) => (
    <Component
      {...props}
      className={`navbar-toggler ${className}`}
      type="button"
      aria-label="Toggle navigation"
      ref={ref}
    >
      <span className="navbar-toggler-icon"></span>
    </Component>
  )
);