import React, { forwardRef } from "react";
import { BSButton } from "../../types";
import { Button } from "./Button";

export const Close = forwardRef<HTMLButtonElement, BSButton>(
  ({ children, component: Component = Button, ...props }, ref) => (
    <Component {...props} variant="close" aria-label="close" ref={ref}>
      <span aria-hidden="true">&times;</span>
    </Component>
  )
);
