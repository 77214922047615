import React, { forwardRef } from "react";
import { BSList } from "../../types";

export const Breadcrumb = forwardRef<HTMLDivElement, BSList>(
  ({ children, className = "", ...props }, ref) => (
    <nav aria-label="breadcrumb" ref={ref}>
      <ol className={`breadcrumb ${className}`} {...props}>
        {children}
      </ol>
    </nav>
  )
);

