import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const ButtonToolbar = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, className = "", ...props }, ref) => (
    <div
      {...props}
      className={`btn-toolbar ${className}`}
      role="toolbar"
      ref={ref}
    >
      {children}
    </div>
  )
);