import React, { forwardRef, useEffect, ReactNode } from "react";
import { motion } from "framer-motion";
import { BSDiv } from "../../types";
import { Close } from "../Button";
import { ToastBody } from "./ToastBody";
import { ToastHeader } from "./ToastHeader";

export interface ToastProps extends Omit<BSDiv, "title"> {
  key?: number;
  title?: ReactNode;
  time?: ReactNode;
  body?: ReactNode;
  dismissable?: boolean;
  icon?: ReactNode;
  duration?: number;
  onDismiss?: () => void;
}

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  (
    {
      className = "",
      dismissable = true,
      title,
      time,
      onDismiss,
      children,
      body,
      icon,
      duration = 5,
      key,
    },
    ref
  ) => {
    useEffect(() => {
      onDismiss && setTimeout(onDismiss, duration * 1000);
    }, [onDismiss]);

    return (
      <motion.div
        ref={ref}
        className={`toast show ${className}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: 1,
          height: "auto",
          transition: { ease: "easeOut" },
        }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        key={key}
      >
        {(icon || title || time || dismissable) && (
          <ToastHeader>
            {icon}
            {title && <strong className="mr-auto">{title}</strong>}
            {time && <small className="text-muted">{time}</small>}
            {dismissable && <Close onClick={onDismiss} className="ml-2 mb-1" />}
          </ToastHeader>
        )}
        <ToastBody>
          {children}
          {body}
        </ToastBody>
        <motion.div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "auto",
            height: 2,
            backgroundImage: "linear-gradient(130deg, #00b4e6, #00f0e0)",
          }}
          animate={{
            right: 0,
            transition: { ease: "easeOut", duration: duration },
          }}
        />
      </motion.div>
    );
  }
);
