import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const CarouselItem = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, className = "", ...props }, ref) => {
    return (
      <div {...props} className={`carousel-item active ${className}`} ref={ref}>
        {children}
      </div>
    );
  }
);