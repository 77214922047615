import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export interface ModalDialogProps extends BSDiv {
  scrollable?: boolean;
  alignment?: "top" | "center";
}

export const ModalDialog = forwardRef<HTMLDivElement, ModalDialogProps>(
  ({ className = "", children, scrollable, alignment, ...props }, ref) => (
    <div
      {...props}
      className={`modal-dialog 
    ${scrollable ? "modal-dialog-scrollable" : ""} 
    ${alignment === "center" ? "modal-dialog-centered" : ""} 
    ${className}`}
      ref={ref}
    >
      {children}
    </div>
  )
);