import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import classnames from "classnames";

export interface CardBodyProps extends BSDiv {
  orientation?: "overlay";
}

export const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(
  ({ children, className = "", orientation, ...props }, ref) => {
    className = classnames(className, {
      "card-body": orientation !== "overlay",
      "card-img-overlay": orientation === "overlay"
    })
    return (
      <div {...props} className={className} ref={ref}>
        {children}
      </div>
    );
  }
);