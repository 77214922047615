import React, { forwardRef, ReactElement } from "react";
import { BSDiv  } from "../../types";
import { motion, AnimatePresence } from "framer-motion";

export interface TabContentProps extends BSDiv {
  index?: number;
  onTab?: () => void;
}

export const TabContent = forwardRef<HTMLDivElement, TabContentProps>(
  ({ index = 0, className = "", onTab, ...props }, ref) => {
    const children: ReactElement[] = React.Children.toArray(
      props.children
    ) as ReactElement[];

    return (
      <div
        {...props}
        className={`tab-content ${className}`}
        role="navigation"
        ref={ref}
      >
        <AnimatePresence
          initial={false}
          exitBeforeEnter={true}
          onExitComplete={onTab}
        >
          <motion.div
            key={index}
            variants={{
              enter: {
                opacity: 0,
              },
              center: {
                opacity: 1,
              },
              exit: {
                opacity: 0,
              },
            }}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {React.cloneElement(children[index], {
              className: "active",
            })}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  }
);