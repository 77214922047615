import React, { forwardRef } from "react";
import { BSMultiElement } from "../../types";
import classnames from "classnames";

export interface ListGroupItemProps extends BSMultiElement {
  active?: boolean;
  disabled?: boolean;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}

export const ListGroupItem = forwardRef<HTMLButtonElement, ListGroupItemProps>(
  (
    {
      children,
      className = "",
      active,
      disabled,
      color,
      href,
      type,
      ...itemProps
    },
    ref
  ) => {
    const props: any = {
      ...itemProps,
      ref,
    };

    props.className = classnames( `list-group-item ${className}`, {
      "active": active,
      "disabled": disabled,
      "list-group-item-action": type === "button" || href,
      [`list-group-item-${color}`]: color
    })

    return type === "button" ? (
      <button {...props} disabled={disabled}>
        {children}
      </button>
    ) : href ? (
      <a {...props} href={href}>
        {children}
      </a>
    ) : (
      <div {...props}>{children}</div>
    );
  }
);