import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const CarouselCaption = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, className = "", ...props }, ref) => {
    return (
      <div
        {...props}
        style={{ transition: "none" }}
        className={`carousel-caption ${className}`}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);