import React, { forwardRef } from "react";
import { BSSvg, Color } from "../../types";

export interface IconProps extends BSSvg {
  name: string;
  path?: string;
  size?: string | number;
  color?: Color;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ name, path="bootstrap-icons.svg", size = 32, color, className, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        className={`bi text-${color} ${className}`}
        width={size}
        height={size}
        fill="currentColor"
        {...props}
      >
        <use href={`${path}#${name}`} />
      </svg>
    );
  }
);