import React, { forwardRef } from "react";
import { BSButton, Color } from "../../types";
import classnames from "classnames";

export interface ButtonProps extends BSButton {
  size?: "large" | "small" | "sm" | "lg";
  variant?: "outline" | "close";
  layout?: "block";
  color?: Color  | "link";
  active?: boolean;
  disabled?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      component: Component = "button",
      children,
      size,
      disabled,
      layout,
      variant,
      color = "primary",
      className = "",
      active,
      ...props
    },
    ref
  ) => {
    const additionalProps: any = {};
    additionalProps.ref = ref;

    className = classnames(`btn ${className}`, {
      "close": variant === "close",
      [`btn-${variant === "outline" ? "outline-" : ""}${color}`]: variant !== "close",
      "btn-lg": size === "large" || size === "lg",
      "btn-sm": size === "small" || size === "sm",
      "btn-block": layout === "block",
      "active": active,
      "disabled": disabled
    })

    if (active) {
      additionalProps["aria-pressed"] = true;
    }

    if (disabled) {
      additionalProps.disabled = true;
    }

    if (props.href) {
      if (disabled) {
        additionalProps.tabIndex = -1;
        additionalProps["aria-disabled"] = true;
      }

      return (
        <a {...props} {...additionalProps} className={className} role="button">
          {children}
        </a>
      );
    }

    if (props.value) {
      return (
        <input
          {...{ ...props, size: undefined }}
          {...additionalProps}
          className={className}
        />
      );
    }

    return (
      <Component
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        {...(additionalProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        className={className}
      >
        {children}
      </Component>
    );
  }
);