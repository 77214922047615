import React, { forwardRef } from "react";
import { DropdownToggleProps } from ".";

export const NavLinkToggle = forwardRef<HTMLAnchorElement, DropdownToggleProps>(
  ({ children, className, onClick, ...props }, ref) => (
    <a
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      href="#"
      className={`nav-link dropdown-toggle ${className}`}
      ref={ref}
    >
      {children}
    </a>
  )
);