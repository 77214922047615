import React, { forwardRef } from "react";
import { BSImg } from "../../types";
import classnames from "classnames";

interface CardImageProps extends BSImg {
  orientation?: "top";
}

export const CardImage = forwardRef<HTMLImageElement, CardImageProps>(
  ({ orientation, className = "", ...props }, ref) => {
    className = classnames(className, {
      "card-img": orientation !== "top",
      "card-img-top": orientation === "top"
    })
    return <img {...props} className={className} ref={ref} />;
  }
);