import React, { forwardRef } from "react";
import { PopperChildrenProps } from "react-popper";
import { motion } from "framer-motion";
import { TooltipPopperProps } from ".";

interface TooltipElementProps
  extends TooltipPopperProps,
    Omit<PopperChildrenProps, "placement" | "style"> {}

export const TooltipElement = forwardRef<HTMLDivElement, TooltipElementProps>(
  (
    { style, placement, arrowProps, className = "", children, ...divProps },
    ref
  ) => (
    <motion.div
      ref={ref}
      style={style}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-placement={placement}
      role="tooltip"
      className={`tooltip bs-tooltip-${placement} ${className}`}
      {...divProps}
    >
      <div className="tooltip-arrow" {...arrowProps}></div>
      <div className="tooltip-inner">{children}</div>
    </motion.div>
  )
);