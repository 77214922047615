import React, { forwardRef } from "react";
import { BSAnchor } from "../../types";

export interface PaginationItemProps extends BSAnchor {
  active?: boolean;
  disabled?: boolean;
}

export const PaginationItem = forwardRef<
  HTMLAnchorElement,
  PaginationItemProps
>(
  (
    { children, className = "", active, disabled, href = "#", component: Component = "a", ...props },
    ref
  ) => {
    if (disabled) {
      props.tabIndex = -1;
      props["aria-disabled"] = "true";
    }

    return (
      <li
        className={`page-item ${active ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <Component
          className={`page-link ${className}`}
          href={href}
          {...props}
          ref={ref}
        >
          {children}
        </Component>
      </li>
    );
  }
);