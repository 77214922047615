import React, { forwardRef } from "react";
import { BSAnchor } from "../../types";
import classnames from "classnames";

export interface BreadcrumbItemProps extends BSAnchor {
  active?: boolean;
}

export const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(
  ({ active, className = "", children, ...props }, ref) => {
    className = classnames(`breadcrumb-item ${className}`, {
      "active": active
    })

    const listItemProps: React.HTMLAttributes<HTMLLIElement> = {};

    if (active) {
      listItemProps["aria-current"] = "page";
    }

    return (
      <li className={className} {...listItemProps} ref={ref}>
        {props.href ? <a {...props}>{children}</a> : children}
      </li>
    );
  }
);