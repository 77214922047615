import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import { motion } from "framer-motion";

export interface CollapseProps extends BSDiv {
  visible?: boolean;
  transition?: "slide" | "none";
}

export const Collapse = forwardRef<HTMLDivElement, CollapseProps>(
  ({ visible, className = "", children }, ref) => {
    return (
      <motion.div
        ref={ref}
        key="content"
        initial={false}
        animate={visible ? "open" : "collapsed"}
        aria-expanded={visible}
        exit="collapsed"
        variants={{
          open: {
            opacity: 1,
            height: "auto",
            transitionEnd: { overflow: "" },
          },
          collapsed: { opacity: 0, height: 0, overflow: "hidden" },
        }}
        transition={{ ease: [0.15, 0.72, 0.34, 1], duration: 0.2 }}
        className={`collapse ${visible ? "show" : ""} ${className}`}
      >
        {children}
      </motion.div>
    );
  }
);
