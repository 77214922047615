import React, { forwardRef } from "react";
import { Button, ButtonProps } from "../Button";
import { SR } from "../SR";

export interface DropdownToggleProps extends ButtonProps {
  split?: boolean;
}

export const DropdownToggle = forwardRef<
  HTMLButtonElement,
  DropdownToggleProps
>(({ children, split, className, component: Component = Button, ...props }, ref) => (
  <Component
    {...props}
    className={`dropdown-toggle ${
      split ? "dropdown-toggle-split" : ""
    } ${className}`}
    ref={ref}
  >
    {children}
    {split && <SR>Toggle Dropdown</SR>}
  </Component>
));