import React, { forwardRef } from "react";
import { BSMotionDiv } from "../../types";
import { motion } from "framer-motion";

export const ModalBackdrop = forwardRef<HTMLDivElement, BSMotionDiv>(
  ({ className = "", children, ...props }, ref) => (
    <motion.div {...props} className={`modal-backdrop ${className}`} ref={ref}>
      {children}
    </motion.div>
  )
);