import React, { forwardRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BSMotionDiv, Color } from "../../types";
import { useSharedState } from "../../hooks/useSharedState";
import { Close } from "../Button";
import classnames from "classnames";

export interface AlertProps extends BSMotionDiv {
  visible?: boolean;
  dismissable?: boolean;
  onDismiss?: (visible: boolean) => void;
  onDismissed?: () => void;
  color?: Color;
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      color = "primary",
      dismissable,
      children,
      onDismiss,
      onDismissed,
      visible = true,
      className = "",
      ...divProps
    },
    ref
  ) => {
    const [_visible, set] = useSharedState(visible, onDismiss);
    const isDismissable = dismissable || onDismiss;

    className = classnames(`alert alert-${color} ${className}`, {
      "alert-dismissible": isDismissable
    })

    return (
      <AnimatePresence initial={false}>
        {_visible && (
          <motion.div
            {...divProps}
            ref={ref}
            initial={false}
            exit={{ opacity: 0 }}
            onAnimationComplete={onDismissed}
            className={className}
            role="alert"
          >
            {children}
            {isDismissable && <Close onClick={() => set(false)} />}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);