import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "./NavLink";

export const NavItem = forwardRef<HTMLLIElement, NavLinkProps>(
  ({ children, className = "", href, ...props }, ref) => {
    if (href) {
      children = (
        <NavLink className={className} href={href} {...props}>
          {children}
        </NavLink>
      );
      className = "";
    }

    return (
      <li className={`nav-item ${className}`} ref={ref}>
        {children}
      </li>
    );
  }
);
