import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const ToastHeader = forwardRef<HTMLDivElement, BSDiv>(
  ({ className = "", children, ...props }, ref) => {
    return (
      <div {...props} className={`toast-header ${className}`} ref={ref}>
        {children}
      </div>
    );
  }
);