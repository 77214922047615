import React, {
  useState,
  ReactNode,
  forwardRef,
  useRef,
  useEffect,
  MutableRefObject,
} from "react";
import { BSDiv, Sizes, SizesAbbr } from "../../types";
import {
  useSizeToAbbr,
  useSizeToBreakpoint,
  useWindowSize,
  isFunction,
} from "../../hooks";
import classnames from "classnames";
import { SimpleNavBar } from "./SimpleNavBar";

export interface NavbarRenderProps {
  set: (v: boolean) => void;
  visible: boolean;
  ref: MutableRefObject<HTMLDivElement | null>;
  className?: string;
}

export interface NavbarProps extends BSDiv {
  color?: "light" | "dark";
  container?: "sm" | "md" | "lg" | "xl" | "xxl" | "fluid" | boolean;
  expand?: Sizes | SizesAbbr | false;
  children?: ReactNode | ((p: NavbarRenderProps) => ReactNode);
  brand?: ReactNode;
}

export const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  (
    {
      children,
      className = "",
      color = "light",
      expand = "sm",
      container,
      brand,
      ...props
    },
    ref
  ) => {
    const size = useWindowSize();
    const expandAbbr = useSizeToAbbr(expand);
    const breakpoint = useSizeToBreakpoint(expandAbbr);
    const navRef = useRef<HTMLDivElement>(null);
    const [visible, set] = useState(!!expand);

    useEffect(() => {
      if (
        navRef.current &&
        window.getComputedStyle(navRef.current).display === "none"
      ) {
        set(false);
      } else if (breakpoint) {
        set(!!(size && size.width && size.width > breakpoint));
      }
    }, [size.width]);

    className = classnames(`navbar ${className}`, {
      [`navbar-${color}`]: color,
      [`navbar-expand-${expandAbbr}`]: expandAbbr,
    });

    const childProps = {
      visible,
      set,
      ref: navRef,
      className: "navbar-collapse",
    };

    let navChildren =
      children && isFunction(children) ? (
        children(childProps)
      ) : (
        <SimpleNavBar {...childProps} brand={brand}>
          {children}
        </SimpleNavBar>
      );

    if (container) {
      navChildren = (
        <div
          className={`container${container !== true ? "-" + container : ""}`}
        >
          {navChildren}
        </div>
      );
    }

    return (
      <nav
        {...props}
        className={`navbar ${className}`}
        role="navigation"
        ref={ref}
      >
        {navChildren}
      </nav>
    );
  }
);
