import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { AnimatePresence } from "framer-motion";
import { BSDiv } from "../../types";
import { ToastProps, Toast } from "./Toast";

export type ToasterAddHandler = (props: ToastProps) => void;
export type ToasterRemoveHandler = (key: number) => void;

export interface ToasterProps extends BSDiv {
  duration?: number;
  children: (add: ToasterAddHandler, remove: ToasterRemoveHandler) => ReactNode;
}

export const Toaster = forwardRef<HTMLDivElement, ToasterProps>(
  ({ className = "", children, duration = 5 }, ref) => {
    const index = useRef(0);
    const [items, setItems] = useState<ToastProps[]>([]);

    useEffect(
      () =>
        void children(
          (props) =>
            setItems((state) => [...state, { key: index.current++, ...props }]),
          (key) => setItems((state) => state.filter((i) => i.key !== key))
        ),
      []
    );

    return (
      <div
        className={className}
        style={{
          position: "fixed",
          bottom: 15,
          right: 15,
          width: "350px",
          zIndex: 1000,
        }}
        ref={ref}
      >
        <AnimatePresence initial={false}>
          {items.map((p) => {
            return (
              <Toast
                {...p}
                duration={p.duration || duration}
                onDismiss={() =>
                  setItems((state) => state.filter((i) => i.key !== p.key))
                }
              />
            );
          })}
        </AnimatePresence>
      </div>
    );
  }
);
