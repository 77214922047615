import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { BSDiv, Triggers, BSMultiElement } from "../../types";
import { useSharedState, useForkedRef } from "../../hooks";
import { DropdownMenu } from "./DropdownMenu";
import { DropdownToggle } from "./DropdownToggle";
import { NavLinkToggle } from "./NavLinkToggle";

interface DropdownProps extends BSDiv {
  alignment?: "left" | "right";
  direction?: "down" | "up" | "left" | "right";
  variant?: "dropdown" | "btn-group" | "nav-item";
  trigger?: Triggers | Triggers[];
  action?: ReactNode;
  toggle: string | ((props: BSMultiElement) => ReactNode);
  visible?: boolean;
  onToggle?: (visible: boolean) => void;
  disabled?: boolean;
}

let counter = 0;
const keydownRegexp = new RegExp("ArrowUp|ArrowDown|Escape");

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      alignment = "left",
      direction = "down",
      variant = "dropdown",
      className = "",
      trigger = "click",
      onToggle,
      children,
      action,
      toggle,
      ...props
    },
    forwardedRef
  ) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const toggleRef = useRef<HTMLButtonElement>(null);
    const ref = useForkedRef(forwardedRef, dropdownRef);

    const [visible, set] = useSharedState(props.visible, onToggle);
    delete props.visible;

    useEffect(() => {
      window.addEventListener("keyup", handleKeyup);

      return () => {
        window.removeEventListener("keyup", handleKeyup);
      };
    });

    const triggers = {
      hover: {
        onMouseEnter: () => set(true),
      },
      focus: {
        onFocus: () => set(true),
        onBlur: () => set(false),
      },
      click: {
        onClick: () => set(!visible),
      },
    };

    if (action) {
      // actions can only exist in btn-groups
      variant = "btn-group";
    }

    const id = `bs-dd-${counter++}`;
    const dropDirection = direction === "down" ? "" : `drop${direction}`;
    const trig = typeof trigger === "string" ? [trigger] : trigger;

    const toggleProps = {
      id,
      "aria-haspopup": true,
      "aria-expanded": visible,
      ref: toggleRef,
      ...trig
        .map((t) => triggers[t])
        .reduce((obj: any, item: any) => {
          for (let i in item) obj[i] = item[i];
          return obj;
        }, {} as BSMultiElement),
    };

    const handleKeyup = (e: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as HTMLElement)
      ) {
        set(false);
      }
    };

    const toggler =
      typeof toggle === "string"
        ? (p: BSMultiElement) =>
            variant === "nav-item" ? (
              <NavLinkToggle {...p}>{toggle}</NavLinkToggle>
            ) : (
              <DropdownToggle {...p}>{toggle}</DropdownToggle>
            )
        : toggle;

    const handleKeyDown = useCallback(
      (e) => {
        if (
          /input|textarea/i.test(e.target.tagName)
            ? e.key === "Space" ||
              (e.key !== "Escape" &&
                ((e.key !== "ArrowDown" && e.key !== "ArrowUp") ||
                  e.target.closest(".dropdown-menu")))
            : !keydownRegexp.test(e.key)
        ) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (props.disabled || e.target.classList.contains("disabled")) {
          return;
        }

        if (e.key === "Escape") {
          toggleRef.current && toggleRef.current.focus();
          return set(false);
        }

        if (!visible || e.key === "Space") {
          return set(false);
        }

        const items = dropdownRef.current
          ? Array.prototype.slice
              .call(
                dropdownRef.current.querySelectorAll(
                  ".dropdown-menu .dropdown-item:not(.disabled):not(:disabled)"
                )
              )
              .filter((element) => {
                if (
                  element &&
                  element.style &&
                  element.parentNode &&
                  element.parentNode.style
                ) {
                  const elementStyle = getComputedStyle(element);
                  const parentNodeStyle = getComputedStyle(element.parentNode);

                  return (
                    elementStyle.display !== "none" &&
                    parentNodeStyle.display !== "none" &&
                    elementStyle.visibility !== "hidden"
                  );
                }

                return false;
              })
          : [];

        if (!items.length) {
          return;
        }

        let index = items.indexOf(e.target);

        if (e.key === "ArrowUp" && index > 0) {
          index--;
        }

        if (e.key === "ArrowDown" && index < items.length - 1) {
          index++;
        }

        // index is -1 if the first keydown is an ArrowUp
        index = index === -1 ? 0 : index;

        items[index].focus();
      },
      [set, visible]
    );

    return (
      <div
        ref={ref}
        {...props}
        className={`dropdown ${variant} ${dropDirection} ${className}`}
        onKeyDown={handleKeyDown}
      >
        {action}
        {toggler(toggleProps)}
        {visible && (
          <div
            onClick={() => set(false)}
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "fixed",
              zIndex: 1000,
            }}
          ></div>
        )}
        <DropdownMenu
          alignment={alignment}
          visible={visible}
          aria-labelledby={id}
          onClick={() => set(!visible)}
        >
          {children}
        </DropdownMenu>
      </div>
    );
  }
);
