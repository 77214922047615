import React, { forwardRef } from "react";
import { BSAnchor } from "../../types";

export const CardLink = forwardRef<HTMLAnchorElement, BSAnchor>(
  ({ children, className = "", component: Component = "a", ...props }, ref) => {
    return (
      <Component {...props} className={`card-link ${className}`} ref={ref}>
        {children}
      </Component>
    );
  }
);