import React, { forwardRef } from "react";
import { BSHeading } from "../../types";

export const CardTitle = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ children, className = "", component: Component = "h5", ...props }, ref) => {
    return (
      <Component {...props} className={`card-title ${className}`} ref={ref}>
        {children}
      </Component>
    );
  }
);