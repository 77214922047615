import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import classnames from "classnames";

export interface ListGroupProps extends BSDiv {
  layout?:
    | "flush"
    | "horizontal"
    | "horizontal-sm"
    | "horizontal-md"
    | "horizontal-lg"
    | "horizontal-xls";
}

export const ListGroup = forwardRef<HTMLDivElement, ListGroupProps>(
  ({ children, layout, className = "", ...props }, ref) => {
    className = classnames(`list-group ${className}`, {
      [`list-group-${layout}`]: layout
    })

    return (
      <div {...props} className={className} ref={ref}>
        {children}
      </div>
    );
  }
);