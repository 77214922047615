import React, { forwardRef } from "react";
import { BSDiv } from "../../types";
import classnames from "classnames";

export interface ButtonGroupProps extends BSDiv {
  size?: "small" | "large" | "sm" | "lg";
  orientation?: "vertical" | "horizontal";
}

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    { children, size, orientation = "horizontal", className = "", ...props },
    ref
  ) => {

    className = classnames(className, {
      "btn-group": orientation === "horizontal",
      "btn-group-vertical": orientation === "vertical",
      "btn-group-sm": size === "small" || size === "sm",
      "btn-group-lg": size === "large" || size === "lg"
    })

    return (
      <div {...props} className={className} role="group" ref={ref}>
        {children}
      </div>
    );
  }
);