import React, { forwardRef } from "react";
import { BSDiv, Color } from "../../types";
import { SR } from "../SR";
import classnames from "classnames";

export interface SpinnerProps extends BSDiv {
  variant?: "border" | "grow";
  size?: "small" | "sm";
  color?: Color;
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (
    { className = "", variant = "border", size, color, children, ...props },
    ref
  ) => {
    className = classnames(`spinner-${variant} ${className}`, {
      [`text-${color}`]: color,
      [`spinner-${variant}-sm`]: size === "small" || size == "sm"
    })
   
    return (
      <div {...props} className={className} ref={ref}>
        <SR>Loading...</SR>
      </div>
    );
  }
);