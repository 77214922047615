import React, { forwardRef } from "react";
import { BSAnchor, Color } from "../../types";
import classnames from "classnames";

export interface BadgeProps extends BSAnchor {
  variant?: "pill" | "rectangle";
  color?: Color;
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      children,
      variant = "rectangle",
      color = "primary",
      className = "",
      component: Component = "span",
      ...props
    },
    ref
  ) => {
    className = classnames(`badge bg-${color} ${className}`, {
      "text-dark": color === "light",
      "rounded-pill": variant === "pill"
    })

    return (
      <Component {...props} className={className} ref={ref}>
        {children}
      </Component>
    );
  }
);