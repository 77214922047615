import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

export const CardFooter = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, className = "", ...props }, ref) => {
    return (
      <div {...props} className={`card-footer ${className}`} ref={ref}>
        {children}
      </div>
    );
  }
);