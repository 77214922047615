import React, { forwardRef } from "react";
import { BSDiv } from "../../types";

interface DropdownMenuProps extends BSDiv {
  visible?: boolean;
  alignment?: "left" | "right";
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    { children, visible, alignment = "left", className = "", ...props },
    ref
  ) => (
    <div
      {...props}
      ref={ref}
      className={`dropdown-menu ${visible ? "show" : ""} ${
        alignment === "right" ? "dropdown-menu-right" : ""
      } ${className}`}
    >
      {children}
    </div>
  )
);